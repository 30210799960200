import React, { Component } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import styles from '../styles/variables.scss'
import styled from 'styled-components'

class CitiesListingTemplate extends Component {
  render() {
    const pageData = this.props

    console.log('Master Listing of Cities', pageData)

    return <Layout>Hello world!</Layout>
  }
}

export default CitiesListingTemplate

export const pageQuery = graphql`
  query serviceAreaQuery($slug: String!) {
    contentfulServiceArea(slug: { eq: $slug }) {
      id
      slug
    }
    allContentfulServiceAreaContent {
      edges {
        node {
          id
          slug
        }
      }
    }
  }
`
